var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{staticClass:"table-padding-2",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Thông số")]),_c('th',{staticClass:"text-center"},[_vm._v("Nội tỉnh")]),_c('th',{staticClass:"text-center"},[_vm._v("Liên tỉnh")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v("Vận chuyển")]),_c('td',{staticClass:"text-center"},[_c('v-autocomplete',{staticClass:"c-input-xs",attrs:{"items":_vm.deliveryOptions,"label":_vm.$t('labels.delivery_company'),"placeholder":_vm.$t('labels.delivery_company'),"dense":"","outlined":"","hide-details":""},model:{value:(_vm.customerContract.id_delivery_contract_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "id_delivery_contract_provincial", $$v)},expression:"customerContract.id_delivery_contract_provincial"}})],1),_c('td',{staticClass:"text-center"},[_c('v-autocomplete',{staticClass:"c-input-xs",attrs:{"items":_vm.deliveryOptions,"label":_vm.$t('labels.delivery_company'),"placeholder":_vm.$t('labels.delivery_company'),"dense":"","outlined":"","hide-details":""},model:{value:(_vm.customerContract.id_delivery_contract_inter_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "id_delivery_contract_inter_provincial", $$v)},expression:"customerContract.id_delivery_contract_inter_provincial"}})],1)]),(
            !_vm.isSscIds.includes(
              _vm.customerContract.id_delivery_contract_provincial
            ) ||
            !_vm.isSscIds.includes(
              _vm.customerContract.id_delivery_contract_inter_provincial
            )
          )?[_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v("Auto đổi vận chuyển")]),_c('td',{staticClass:"text-center vertical-align-middle"},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-checkbox',{staticClass:"mt-0 ml-1",attrs:{"disabled":_vm.isSscIds.includes(
                      _vm.customerContract.id_delivery_contract_provincial
                    ) || _vm.deliveryContracts.length < 2,"value":1,"hide-details":""},model:{value:(
                    _vm.customerContract.auto_change_delivery_company_provincial
                  ),callback:function ($$v) {_vm.$set(_vm.customerContract, "auto_change_delivery_company_provincial", $$v)},expression:"\n                    customerContract.auto_change_delivery_company_provincial\n                  "}})],1)]),_c('td',{staticClass:"text-center vertical-align-middle"},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-checkbox',{staticClass:"mt-0 ml-1",attrs:{"disabled":_vm.isSscIds.includes(
                      _vm.customerContract.id_delivery_contract_inter_provincial
                    ) || _vm.deliveryContracts.length < 2,"value":1,"hide-details":""},model:{value:(
                    _vm.customerContract.auto_change_delivery_company_inter_provincial
                  ),callback:function ($$v) {_vm.$set(_vm.customerContract, "auto_change_delivery_company_inter_provincial", $$v)},expression:"\n                    customerContract.auto_change_delivery_company_inter_provincial\n                  "}})],1)])]),_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" SSC vận chuyển "),_c('br'),_vm._v(" (khi cần) ")]),_c('td',{staticClass:"text-center vertical-align-middle"},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-checkbox',{staticClass:"mt-0 ml-1",attrs:{"disabled":_vm.isSscIds.includes(
                      _vm.customerContract.id_delivery_contract_provincial
                    ),"value":1,"hide-details":""},model:{value:(_vm.customerContract.ssc_delivery_company_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "ssc_delivery_company_provincial", $$v)},expression:"customerContract.ssc_delivery_company_provincial"}})],1)]),_c('td',{staticClass:"text-center vertical-align-middle"},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-checkbox',{staticClass:"mt-0 ml-1",attrs:{"disabled":_vm.isSscIds.includes(
                      _vm.customerContract.id_delivery_contract_inter_provincial
                    ),"value":1,"hide-details":""},model:{value:(
                    _vm.customerContract.ssc_delivery_company_inter_provincial
                  ),callback:function ($$v) {_vm.$set(_vm.customerContract, "ssc_delivery_company_inter_provincial", $$v)},expression:"\n                    customerContract.ssc_delivery_company_inter_provincial\n                  "}})],1)])]),(
              !_vm.isSscIds.includes(
                _vm.customerContract.id_delivery_contract_provincial
              ) ||
              !_vm.isSscIds.includes(
                _vm.customerContract.id_delivery_contract_inter_provincial
              )
            )?[_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" Khai giảm khối lượng "),_c('br'),_vm._v(" (300đ /đơn) ")]),_c('td',{staticClass:"text-center"},[_c('v-text-field',{staticClass:"c-input-small c-input-mini-append-icon",attrs:{"disabled":_vm.isSscIds.includes(
                      _vm.customerContract.id_delivery_contract_provincial
                    ),"placeholder":_vm.$t('labels.gram'),"type":"number","append-icon":"g","outlined":"","dense":"","hide-details":"","single-line":""},model:{value:(_vm.customerContract.decrease_weight_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "decrease_weight_provincial", _vm._n($$v))},expression:"customerContract.decrease_weight_provincial"}})],1),_c('td',{staticClass:"text-center"},[_c('v-text-field',{staticClass:"c-input-small c-input-mini-append-icon",attrs:{"disabled":_vm.isSscIds.includes(
                      _vm.customerContract.id_delivery_contract_inter_provincial
                    ),"placeholder":_vm.$t('labels.gram'),"type":"number","append-icon":"g","outlined":"","dense":"","hide-details":"","single-line":""},model:{value:(
                    _vm.customerContract.decrease_weight_inter_provincial
                  ),callback:function ($$v) {_vm.$set(_vm.customerContract, "decrease_weight_inter_provincial", _vm._n($$v))},expression:"\n                    customerContract.decrease_weight_inter_provincial\n                  "}})],1)]),_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" Khai cố định khối lượng "),_c('br'),_vm._v(" (500đ /đơn) ")]),_c('td',{staticClass:"text-center"},[_c('v-text-field',{staticClass:"c-input-small c-input-mini-append-icon",attrs:{"disabled":_vm.isSscIds.includes(
                      _vm.customerContract.id_delivery_contract_provincial
                    ),"placeholder":_vm.$t('labels.gram'),"type":"number","append-icon":"g","outlined":"","dense":"","hide-details":"","single-line":""},model:{value:(_vm.customerContract.fixed_weight_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "fixed_weight_provincial", _vm._n($$v))},expression:"customerContract.fixed_weight_provincial"}})],1),_c('td',{staticClass:"text-center"},[_c('v-text-field',{staticClass:"c-input-small c-input-mini-append-icon",attrs:{"disabled":_vm.isSscIds.includes(
                      _vm.customerContract.id_delivery_contract_inter_provincial
                    ),"placeholder":_vm.$t('labels.gram'),"type":"number","append-icon":"g","outlined":"","dense":"","hide-details":"","single-line":""},model:{value:(
                    _vm.customerContract.fixed_weight_inter_provincial
                  ),callback:function ($$v) {_vm.$set(_vm.customerContract, "fixed_weight_inter_provincial", _vm._n($$v))},expression:"\n                    customerContract.fixed_weight_inter_provincial\n                  "}})],1)]),_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v("Khối lượng tối thiểu")]),_c('td',{staticClass:"text-center error--text font-italic"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.minWeightProvincial))+" gram ")]),_c('td',{staticClass:"text-center error--text font-italic"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.minWeightInterProvincial))+" gram ")])])]:_vm._e()]:_vm._e(),(
            _vm.isSscIds.includes(
              _vm.customerContract.id_delivery_contract_provincial
            ) ||
            _vm.isSscIds.includes(
              _vm.customerContract.id_delivery_contract_inter_provincial
            )
          )?[_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v("Bảng Cước")]),_c('td',{staticClass:"text-center"},[_c('v-autocomplete',{staticClass:"c-input-xs",attrs:{"disabled":_vm.customerContract.id_delivery_contract_provincial ===
                  _vm.tvcContract,"items":_vm.costByOptions,"label":_vm.$t('labels.delivery_company'),"placeholder":_vm.$t('labels.delivery_company'),"dense":"","outlined":"","hide-details":""},model:{value:(_vm.customerContract.cost_by_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "cost_by_provincial", $$v)},expression:"customerContract.cost_by_provincial"}})],1),_c('td',{staticClass:"text-center"},[_c('v-autocomplete',{staticClass:"c-input-xs",attrs:{"disabled":_vm.customerContract.id_delivery_contract_inter_provincial ===
                  _vm.tvcContract,"items":_vm.costByOptions,"label":_vm.$t('labels.delivery_company'),"placeholder":_vm.$t('labels.delivery_company'),"dense":"","outlined":"","hide-details":""},model:{value:(_vm.customerContract.cost_by_inter_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "cost_by_inter_provincial", $$v)},expression:"customerContract.cost_by_inter_provincial"}})],1)]),_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v("Tăng cước theo")]),_c('td',{staticClass:"text-center"},[_c('v-autocomplete',{staticClass:"c-input-xs",attrs:{"disabled":_vm.customerContract.id_delivery_contract_provincial ===
                  _vm.tvcContract,"items":_vm.addCostByOptions,"label":_vm.$t('labels.add_cost_by'),"placeholder":_vm.$t('labels.add_cost_by'),"dense":"","outlined":"","hide-details":""},model:{value:(_vm.customerContract.add_cost_provincial_by),callback:function ($$v) {_vm.$set(_vm.customerContract, "add_cost_provincial_by", $$v)},expression:"customerContract.add_cost_provincial_by"}})],1),_c('td',{staticClass:"text-center"},[_c('v-autocomplete',{staticClass:"c-input-xs",attrs:{"disabled":_vm.customerContract.id_delivery_contract_inter_provincial ===
                  _vm.tvcContract,"items":_vm.addCostByOptions,"label":_vm.$t('labels.add_cost_by'),"placeholder":_vm.$t('labels.add_cost_by'),"dense":"","outlined":"","hide-details":""},model:{value:(_vm.customerContract.add_cost_inter_provincial_by),callback:function ($$v) {_vm.$set(_vm.customerContract, "add_cost_inter_provincial_by", $$v)},expression:"customerContract.add_cost_inter_provincial_by"}})],1)]),_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v("Tăng")]),_c('td',{staticClass:"text-center error--text font-italic"},[(_vm.customerContract.add_cost_provincial_by === 1)?_c('vuetify-money',{staticClass:"c-input-small",attrs:{"disabled":_vm.customerContract.id_delivery_contract_provincial ===
                  _vm.tvcContract,"options":_vm.moneyOptions,"type":"number","outlined":"","dense":"","hide-details":"","single-line":""},model:{value:(_vm.customerContract.add_cost_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "add_cost_provincial", _vm._n($$v))},expression:"customerContract.add_cost_provincial"}}):_vm._e(),(_vm.customerContract.add_cost_provincial_by === 2)?_c('v-text-field',{staticClass:"c-input-small c-input-mini-append-icon",attrs:{"disabled":_vm.customerContract.id_delivery_contract_provincial ===
                  _vm.tvcContract,"type":"number","append-icon":"%","outlined":"","dense":"","hide-details":"","single-line":""},model:{value:(_vm.customerContract.add_cost_percent_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "add_cost_percent_provincial", _vm._n($$v))},expression:"customerContract.add_cost_percent_provincial"}}):_vm._e()],1),_c('td',{staticClass:"text-center error--text font-italic"},[(_vm.customerContract.add_cost_inter_provincial_by === 1)?_c('vuetify-money',{staticClass:"c-input-small",attrs:{"disabled":_vm.customerContract.id_delivery_contract_inter_provincial ===
                  _vm.tvcContract,"options":_vm.moneyOptions,"type":"number","outlined":"","dense":"","hide-details":"","single-line":""},model:{value:(_vm.customerContract.add_cost_inter_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "add_cost_inter_provincial", _vm._n($$v))},expression:"customerContract.add_cost_inter_provincial"}}):_vm._e(),(_vm.customerContract.add_cost_inter_provincial_by === 2)?_c('v-text-field',{staticClass:"c-input-small c-input-mini-append-icon",attrs:{"disabled":_vm.customerContract.id_delivery_contract_inter_provincial ===
                  _vm.tvcContract,"type":"number","append-icon":"%","outlined":"","dense":"","hide-details":"","single-line":""},model:{value:(
                  _vm.customerContract.add_cost_percent_inter_provincial
                ),callback:function ($$v) {_vm.$set(_vm.customerContract, "add_cost_percent_inter_provincial", _vm._n($$v))},expression:"\n                  customerContract.add_cost_percent_inter_provincial\n                "}}):_vm._e()],1)])]:_vm._e(),_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("labels.insurance_value_by"))+" ")]),_c('td',{staticClass:"text-center"},[_c('v-autocomplete',{staticClass:"c-input-xs",attrs:{"disabled":_vm.customerContract.id_delivery_contract_provincial ===
                _vm.tvcContract,"items":_vm.insuranceValueByOptions,"label":_vm.$t('labels.insurance_value_by'),"placeholder":_vm.$t('labels.insurance_value_by'),"dense":"","outlined":"","hide-details":""},model:{value:(_vm.customerContract.insurance_value_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "insurance_value_provincial", $$v)},expression:"customerContract.insurance_value_provincial"}})],1),_c('td',{staticClass:"text-center"},[_c('v-autocomplete',{staticClass:"c-input-xs",attrs:{"disabled":_vm.customerContract.id_delivery_contract_inter_provincial ===
                _vm.tvcContract,"items":_vm.insuranceValueByOptions,"label":_vm.$t('labels.insurance_value_by'),"placeholder":_vm.$t('labels.insurance_value_by'),"dense":"","outlined":"","hide-details":""},model:{value:(_vm.customerContract.insurance_value_inter_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "insurance_value_inter_provincial", $$v)},expression:"customerContract.insurance_value_inter_provincial"}})],1)]),_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("labels.insurance_value_amount"))+" ")]),_c('td',{staticClass:"text-center"},[(
                [1, 2].includes(_vm.customerContract.insurance_value_provincial)
              )?_c('input-number',{staticClass:"c-input-small",attrs:{"disabled":_vm.customerContract.id_delivery_contract_provincial ===
                _vm.tvcContract,"outlined":"","dense":"","hide-details":"","single-line":""},model:{value:(_vm.customerContract.insurance_value_amount_provincial),callback:function ($$v) {_vm.$set(_vm.customerContract, "insurance_value_amount_provincial", $$v)},expression:"customerContract.insurance_value_amount_provincial"}}):_vm._e()],1),_c('td',{staticClass:"text-center"},[(
                [1, 2].includes(
                  _vm.customerContract.insurance_value_inter_provincial
                )
              )?_c('input-number',{staticClass:"c-input-small",attrs:{"disabled":_vm.customerContract.id_delivery_contract_inter_provincial ===
                _vm.tvcContract,"outlined":"","dense":"","hide-details":"","single-line":""},model:{value:(
                _vm.customerContract.insurance_value_amount_inter_provincial
              ),callback:function ($$v) {_vm.$set(_vm.customerContract, "insurance_value_amount_inter_provincial", $$v)},expression:"\n                customerContract.insurance_value_amount_inter_provincial\n              "}}):_vm._e()],1)]),_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"3"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("labels.save"))+" ")])],1)])],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }